<template>
  <div class="my-4">
   <div class="d-flex justify-space-between align-center">
      <p class="subtitle-1 font-weight-bold">{{ label }}</p>
      <v-btn
        class="ma-2"
        dark
        outlined
        color="secondary"
        @click="addImage"
      >
        <v-icon>mdi-upload</v-icon>
        Upload
      </v-btn>
   </div>
    <v-row>
        <v-col sm="6" v-for="(image, index) in images" :key="'uploaded-' + index">
            <v-card class="preview-container" outlined :img="getImageSrc(image)">
                <div class="preview-btn-container">
                    <v-btn dark icon x-large class="mx-2" color="primary"
                    :disabled="isFailedImage(image)" @click="preview(image)">
                    <v-icon>mdi-eye</v-icon>
                    </v-btn>
                </div>
                <v-btn fab dark small class="remove" color="danger"
                  @click="remove(image)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card>
        </v-col>
        <v-col sm="6" v-for="(upload, index) in uploads" :key="'upload-' + index">
            <v-card class="upload-image" outlined :img="previewUploaded(index)">
                <v-btn fab dark small class="remove" color="danger"
                  @click="removeTemp(index)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-actions class="mt-auto">
                    <v-file-input
                        :ref="'upload-' + index"
                        v-model="uploads[index][srcKey]"
                        label="Upload image"
                        accept="image/png, image/jpeg, image/webp"
                        prepend-icon="mdi-camera-outline"
                        truncate-length="10"
                        :rules="rules"
                        dense
                    ></v-file-input>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <Lightbox v-model="thumbnailDialog" :src="thumbnailSrc"></Lightbox>
  </div>
</template>

<script>
import Lightbox from "@/components/Lightbox";
import rule from "@/shared/rules";
import { mapActions } from "vuex";

export default {
  components: { Lightbox },
  name: "Gallery",
  props: ["value", "src-key", "label", "rules"],
  data() {
    return {
      rule,
      images: [],
      failedImages: [],
      uploads: [],
      thumbnailSrc: null,
      thumbnailDialog: false,
      imageDefault: 'https://i.stack.imgur.com/y9DpT.jpg',
      imageURLPrefix: `${process.env.VUE_APP_API_URL}/commands/thumbnail?resource=`
    }
  },
  mounted() {
    this.images = this.value;
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    getImageId(image) {
      return _.get(image, 'id', _.get(image, 'ID'));
    },
    onFailedImages(image) {
      this.failedImages.push(this.getImageId(image));
    },
    isFailedImage(image) {
      return _.includes(this.failedImages, this.getImageId(image));
    },
    remove(toRemoveImage) {
      this.images = _.reject(this.images, (image) => image === toRemoveImage);
      this.showSnackbar('Press "SAVE CHANGES" to apply')
      this.$emit("input", {
        uploads: _.get(this.value, 'uploads'),
        remove: [
          ...(_.get(this.value, 'remove') || []),
          this.getImageId(toRemoveImage)
        ],
      });
    },
    removeTemp(index) {
      this.uploads = _.reject(this.uploads, (upload, i) => i === index);
    },
    preview(image) {
      const imageName = _.get(image, this.srcKey);
      this.thumbnailSrc = `${this.imageURLPrefix}${imageName}`;
      this.thumbnailDialog = true;
    },
    previewUploaded(index) {
      const imageBlob = _.get(this.uploads, `${index}.${this.srcKey}`);
      if (!imageBlob) {
        return this.imageDefault;
      }

      return URL.createObjectURL(imageBlob);
    },
    getImageSrc(image) {
      const imageName = _.get(image, this.srcKey);
      if (this.isFailedImage(image)) {
        return this.imageDefault;
      }

      return imageName
        ? `${this.imageURLPrefix}${imageName}`
        : this.imageDefault;
    },
    addImage() {
      this.uploads.push({ [this.srcKey]: null });
      setTimeout(() => {
        const index = _.size(this.uploads) - 1;
        this.$refs[`upload-${index}`][0].$refs.input.click();
      }, 100);
    }
  },
  watch: {
    uploads: {
      deep: true,
      immediate: true,
      handler(uploads) {
        const newUpload = _.last(uploads);
        if (_.get(newUpload, this.srcKey) || _.isEmpty(uploads)) {
          this.$emit("input", {
            uploads: _.map(uploads, (upload) => upload[this.srcKey]),
            remove: _.get(this.value, 'remove'),
          });
        }
      },
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes fadeIn {
  0% { opacity: 0; }
  20% { opacity: 0.2; }
  50% { opacity: 0.4; }
  70% { opacity: 0.6; }
  100% { opacity: 0.8; }
}

.v-card {
  height: 300px;

  &.upload-image {
    display: flex;
    flex-direction: column;

    .v-card__actions {
      background: white;
      padding-top: 20px;
      border: 1px solid gainsboro;
      margin: 0 -1px -2px;
    }
  }

  .remove {
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 3;
  }
}

.preview-container {
  position: relative;

  &:hover .preview-btn-container {
    display: block;
  }

  .preview-btn-container {
    display: none;
    position: absolute;
    top: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
    background-color: #1e1e1ecc;
    animation: fadeIn 0.3s;
    border-radius: inherit;

    &:hover {
      display: block;
    }

    button {
      position: absolute;
      top: 40%;
      right: 40%;
    }
  }
}


</style>
